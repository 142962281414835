/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from "react"
import SEO from '../components/seo'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import AboutCVD3 from "../components/aboutcvd3"
// import AboutChart from "../components/aboutchart"
import AboutWhy from "../components/aboutwhy"
import AboutBooks from "../components/aboutbooks"
import { Box, Text, Grid, } from "theme-ui"
import Tippy from '@tippyjs/react'
import '../styles/tippy.css'
import * as d3 from "d3"
import {
  AiOutlineTrophy,
  // AiOutlineStar,
  // AiOutlineArrowRight,
  AiOutlineClockCircle,
} from "react-icons/ai"
import {
  animated, useTransition, config,
} from "react-spring"
import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"
import { slugify } from '../components/_helpers'
import theme from "../gatsby-plugin-theme-ui"
momentDurationFormatSetup(moment)

const About = ({ data }) => {
  const AnimatedBox = animated(Box)
  const AnimatedAboutCVD3 = animated(AboutCVD3)
  // const AnimatedAboutChart = animated(AboutChart)

  const enterOpacity = .5
  function CVMagic(item) {
    if (item.cvEndDate === null) {
      item.cvEndDate = moment().format("YYYY-MM-DD")
      item.cvOngoing = "Present"
    }
    var cvStartDate = moment(item.cvStartDate, "YYYY-MM-DD").add(1, 'days')
    var cvEndDate = moment(item.cvEndDate, "YYYY-MM-DD").add(1, 'days')
    var cvDuration1 = cvEndDate.diff(cvStartDate, "months")
    var cvDuration = moment
      .duration(cvDuration1, "months")
      .format("y [years], M [months], w [weeks], d [days]", {
        largest: 2,
      })
    item.cvDuration = cvDuration
    if (cvDuration1 < 6) {
      item.cvThickness = 1
    } else if (cvDuration1 < 12) {
      item.cvThickness = 2
    } else if (cvDuration1 > 12) {
      item.cvThickness = 3
    }
    item.slug = slugify(item.cvTitle)
    item.StartDate = moment(cvStartDate).format("MMM[']YY")
    item.EndDate = moment(cvEndDate).format("MMM[']YY")

    if (item.achievement[0] !== undefined) {
      item.achievementTitle = item.achievement[0].achievementTitle
      item.achievementDescription = item.achievement[0].achievementDescription
      item.achievementDate = moment(item.achievement[0].achievementDate, "YYYY-MM-DD").add(2, 'days').format('MMMM Do YYYY')
    }
  }

  data.strapiAbout.cv.forEach(CVMagic)
  const CVRows = Math.ceil(data.strapiAbout.cv.length / 4)
  const allCVReversed = data.strapiAbout.cv.sort(
    (a, b) =>
    d3.descending(new Date(a.cvStartDate), new Date(b.cvStartDate)) ||
    d3.descending(new Date(a.cvEndDate), new Date(b.cvEndDate))
  )
  //   const allCVReversed2 = data.strapiAbout.cv.sort(function(a,b){ 
  //     if(a.cvOngoing === 'Present' && b.cvOngoing === 'Present' && a.cvStartDate > b.cvStartDate)
  //     return d3.descending(new Date(a.cvStartDate), new Date(b.cvStartDate))
  //     else
  //     d3.descending(new Date(a.cvEndDate), new Date(b.cvEndDate)) ||
  //     d3.ascending(new Date(a.cvStartDate), new Date(b.cvStartDate))
  // })


  const transitionCV = useTransition(
    allCVReversed,
    {
      key: item => data.strapiAbout.cv.indexOf(item),
      config: config.gentle,
      trail: 100,
      from: {
        opacity: 0.01,
        transform: "perspective(600px) rotateX(-45deg) rotateY(-45deg)",
      },
      enter: {
        opacity: enterOpacity,
        transform: "perspective(600px) rotateX(0deg) rotateY(0deg)",
      },
      leave: {
        opacity: 0.01,
        transform: "perspective(600px) rotateX(0deg) rotateY(90deg)",
      },
    }
  )

  return (
    <Layout>
      <SEO title="About" />
      <AboutWhy cover={data.strapiAbout.cover} circles={data.strapiAbout.circles}/>
      <AboutBooks currentBook={data.currentBook} nextBook={data.nextBook}></AboutBooks>
      {/* <AnimatedAboutChart enterOpacity={enterOpacity} theme={{ theme }} /> */}
      <AnimatedAboutCVD3 cv={allCVReversed} enterOpacity={enterOpacity} theme={{ theme }} />
      {/* <Img fixed={data.strapiAbout.cover.childImageSharp.fixed}/> */}
      <Grid
        sx={{
          gridTemplateRows: ['repeat(1, 1fr)', `repeat(${CVRows}, 1fr)`],
          gridTemplateColumns: ["repeat(3, 1fr)", "repeat(12, 1fr)"],
          gridRowGap: "0",
          gridColumnGap: "5",
          gridAutoFlow: ["row", "column"],
          lineHeight: "1",
          mt: "4"
          // fontSize:'13px',
        }}
        className="timelineContainer"
      >
        {transitionCV((style, item) => {
          return (
            <AnimatedBox
              style={style}
              className="cvBox"
              id={item.slug}
              onMouseEnter={() =>
                d3.selectAll(`#${item.slug}`)
                  .style("color", `${theme.colors.primary}`)
                  .style("opacity", 1)
              }
              onMouseLeave={() =>
                d3.selectAll(`#${item.slug}`)
                  .style("color", `${theme.colors.text}`)
                  .style("opacity", enterOpacity)
              }
              sx={{
                position: "relative",
                gridColumnStart: "span 3",
                // gridColumnEnd: "3",
                cursor: "default",
                pl: "2",
                borderTop: `solid ${item.cvThickness}px`,
                mb: '2'
              }}
              // gridRowStart: `${item.row}`
              // gridColumnStart: `span ${item.cvDuration}`
              px={"0"}
              py={"0"}
              key={item.key}
            >
              <Box sx={{ pt: '2', mb: '4' }}>
                {item.cvOngoing ? (
                  <code css={{
                    fontSize: "10px",
                    position: "absolute",
                    top: "-14px",
                    left: "0",
                  }}>
                    {item.cvOngoing}
                  </code>) : (
                    <code css={{
                      fontSize: "10px",
                      position: "absolute",
                      top: "-14px",
                      left: "0",
                    }}>
                      {item.EndDate}
                    </code>
                  )}
                <code
                  css={{
                    fontSize: "10px",
                    position: "absolute",
                    top: "-14px",
                    right: "0",
                    textAlign: "right",
                  }}>
                  {item.StartDate}
                </code>

                <Text sx={{ fontWeight: 'medium' }}>{item.cvTitle}</Text>

                <Text sx={{ fontSize: '0', fontWeight: 'light', mt: '1', mb: '1' }}>{item.cvDescription}</Text>
                {item.achievementTitle ? (
                <Tippy
                  interactive={true}
                  animation={'shift-away'}
                  placement={'left-end'}
                  arrow={false}
                  content={
                  <>
                    <Text sx={{fontSize:'12px'}}>{item.achievementDate}</Text>
                    <Text sx={{my:'1',fontSize:'14px',fontWeight:'medium', lineHeight:'list'}}>{item.achievementTitle}</Text>
                    <Text sx={{fontSize:'12px'}}>{item.achievementDescription}</Text>
                  </>
                  }>
                  <Box>
                    <AiOutlineTrophy
                      sx={{
                        fontSize: '2',
                        marginLeft: "-19px",
                        position: 'absolute',
                        top: '8px'}}
                    />
                  </Box>
                </Tippy>
                ) : (null
                  )}
                <AiOutlineClockCircle
                  sx={{
                    marginLeft: "-18px",
                    position: 'absolute'
                  }} />
                <Text sx={{ display: 'block', pt: '2px', fontSize: '0', fontFamily: 'monospace' }}>{item.cvDuration}</Text>

              </Box>
            </AnimatedBox>
          )
        })}
      </Grid>
      {/* <ReactMarkdown
        source={data.strapiAbout.description}
      /> */}
    </Layout>
  )
}

export default About

export const query = graphql`
  query About {
    currentBook {
      currentBook
      title
      subtitle
      authors
      publisher
      publishedDate
      thumbnail
    }
    nextBook {
      currentBook
      title
      subtitle
      authors
      publisher
      publishedDate
      thumbnail
    }
    strapiAbout {
      circles{
        title
        subtitle
        summary
        description
      }
      cv {
        cvTitle
        cvDescription
        cvStartDate
        cvEndDate
        achievement {
          achievementTitle
          achievementDescription
          achievementDate
        }
      }
      description
      cover {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
