/** @jsx jsx */
import { jsx, Box, Grid, Text } from "theme-ui"
import React, { useState } from "react"
import { animated, useTransition, config, useSpring } from "react-spring"
import Img from "gatsby-image"

export default function AboutWhy({ circles, cover }) {
  const AnimatedBox = animated(Box)
  const AnimatedImg = animated(Img)
  const [index, set] = useState(0)
  const fade = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: "perspective(600px) scale(0)" },
    to: async next => {
      await next({ opacity: 1, transform: "perspective(600px) scale(1)" })
    },
  })

  const handleClick = event => {
    event.preventDefault()
    set(event.target.getAttribute("data-id"))
  }

  const transitionCircles = useTransition(circles[index], {
    key: item => circles.indexOf(item),
    config: config.gentle,
    from: {
      gridColumnStart: "4",
      gridColumnEnd: "9",
      gridRowStart: "1",
      gridRowEnd: "2",
      height: "380px",
      opacity: 0.01,
      transform: "perspective(600px) rotateX(90deg)",
    },
    enter: {
      opacity: 1,
      transform: "perspective(600px) rotateX(0deg)",
    },
    leave: {
      opacity: 0.01,
      transform: "perspective(600px) rotateX(-90deg)",
    },
  })

  return (
    <>
      <Grid columns={["repeat(3,1fr)","repeat(12,1fr)"]} sx={{ overflow: "hidden", mt: "4" }}>
        <AnimatedImg
          style={fade}
          sx={{
            gridColumnStart: "span 3",
            borderRadius: "50%",
            zIndex: "-1",
            mt: "3",
            mx: "auto",
          }}
          fixed={cover.childImageSharp.fixed}
        />
        {transitionCircles((style, item) => {
          return (
            <AnimatedBox
              sx={{
                fontSize: "2",
                overflow: "hidden",
                // position: 'absolute',
                // top: '80px',
                // left: '750px',
                // display: "inline-block",
                // mx: ["2", "3", "4", "5"],
                pt: "2",
                zIndex: "1"
              }}
              style={style}
            >
              <Text
                as="span"
                sx={{
                  fontSize: "4",
                  fontWeight: "medium",
                  backgroundColor: "primary",
                  p: "2",
                  boxShadow: "project.postit",
                }}
              >
                <i sx={{ fontWeight: "light" }}>my</i> {item.title}
              </Text>
              <Text
                sx={{
                  my: "2",
                  fontSize: "3",
                  lineHeight: "list",
                  color: "text",
                  fontWeight: "medium",
                }}
              >
                {item.summary}
              </Text>
              <hr />
              <Text
                sx={{
                  my: "1",
                  color: "primary",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  textDecoration: "blink",
                }}
              >
                {item.subtitle}
              </Text>
              <Text
                sx={{
                  fontSize: "2",
                  fontWeight: "body",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item.description}
              </Text>
            </AnimatedBox>
          )
        })}
        <AnimatedBox style={fade} sx={{ gridColumnStart: "span 4", mx:"auto", mt: "3" }}>
          <svg width={300} height={300}>
            <defs>
              <path
                xmlns="http://www.w3.org/2000/svg"
                id="WHYPATH"
                stroke="lightblue"
                fill="none"
                d="M 105, 150 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                id="HOWPATH"
                stroke="lightblue"
                fill="none"
                d="M 60, 150 a 90,90 0 1,0 180,0 a 90,90 0 1,0 -180,0z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                id="WHATPATH"
                stroke="lightblue"
                fill="none"
                d="M 15, 150 a 135,135 0 1,0 270,0 a 135,135 0 1,0 -270,0z"
              />
            </defs>

            <circle
              onClick={handleClick}
              data-id="2"
              className={`${index}` !== "2" ? "circles" : "circles active"}
              cx={150}
              cy={150}
              r={150}
            />
            <circle
              onClick={handleClick}
              data-id="1"
              className={`${index}` !== "1" ? "circles" : "circles active"}
              cx={150}
              cy={150}
              r={100}
            />
            <circle
              onClick={handleClick}
              data-id="0"
              className={`${index}` !== "0" ? "circles" : "circles active"}
              cx={150}
              cy={150}
              r={50}
            />
            <text
              sx={{
                animation: "rotateText 50s linear infinite reverse",
              }}
              dy="-5" 
              className="circlesText"
              textAnchor="middle"
              pointerEvents="none"
              x={150}
              y={155}
            >

              <textPath
              textLength="270"
              xlinkHref="#WHYPATH">
          {/* <animate attributeName="textLength" values="270;500;270" dur="5s"  repeatCount="indefinite" /> */}

                <tspan>
                {"WHY WHY WHY WHY"}
                </tspan>
              </textPath>
            </text>

            <text
              dy="-5"
              sx={{
                animation: "rotateText 100s linear infinite",
              }}
              className="circlesText"
              textAnchor="middle"
              pointerEvents="none"
              x={150}
              y={227}
            >
       {/* <animate attributeName="dx" values="0%;100%" dur="35s"  repeatCount="indefinite" /> */}

              <textPath
              textLength="995"
              xlinkHref="#HOWPATH">

                {"HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW"}
              </textPath>
            </text>
            <text
              sx={{
                animation: "rotateText 100s linear infinite reverse",
              }}
              dy="-5"
              className="circlesText"
              textAnchor="middle"
              pointerEvents="none"
              x={150}
              y={280}
            >
              <textPath
              textLength="2100"
              xlinkHref="#WHATPATH">

                {"WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT WHAT"}
              </textPath>
            </text>
          </svg>
        </AnimatedBox>
      </Grid>
    </>
  )
}
