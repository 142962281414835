/** @jsx jsx */
import { jsx } from 'theme-ui'
// import React, { Component } from "react"
import { Component } from "react"
import moment from "moment"
import * as d3 from "d3"
import { slugify } from './_helpers'

class AboutCVD3 extends Component {
  componentDidMount() {
    const data = this.props.cv.sort(
      (a, b) =>
        d3.ascending(new Date(a.cvStartDate), new Date(b.cvStartDate)) ||
        d3.descending(new Date(a.cvEndDate), new Date(b.cvEndDate))
    )
    const themer = this.props.theme.theme
    const enterOpacity = this.props.enterOpacity
    const lanesData = []
    let stack = []
    data.slice().forEach(e => {
      var d3StartDate = moment(e.cvStartDate, "YYYY-MM-DD")
      var d3EndDate = moment(e.cvEndDate, "YYYY-MM-DD")
      const lane = stack.findIndex(
        s => s.cvEndDate <= e.cvStartDate && s.cvStartDate < e.cvStartDate
      )
      const yIndex = lane === -1 ? stack.length : lane

      lanesData.push({
        ...e,
        yIndex,
        d3StartDate,
        d3EndDate,
      })
      stack[yIndex] = e
    })
    this.renderData(lanesData, themer, enterOpacity)
  }
  renderData(events, themer, enterOpacity) {
    const H = 50
    const W = 1200
    const M = 5
    const anidur = 250
    const anidelay = anidur / 2
    const aniease = d3.easeBackOut.overshoot(0.999)
    const svg = d3.select(this.refs.canvas)

    const xScale = d3
      .scaleTime()
      //reversed
      //.domain([moment(events[0].cvStartDate, "YYYY-MM-DD").toDate(), new Date()])
      .domain([new Date(), moment(events[0].cvStartDate, "YYYY-MM-DD").toDate()])
      .range([M, W - M])
    const nRows = d3.max(events, d => d.yIndex) + 1
    const yScale = d3
      .scaleBand()
      .domain(d3.range(0, nRows))
      .range([M, H + M])
    // .paddingInner(0)

    var xAxis = d3
      //reversed
      //.axisTop(xScale)
      .axisBottom(xScale)
      .ticks(d3.timeMonth.every(6))
      .tickSize(-6)
      .tickFormat(date => {
        if (d3.timeYear(date) < date) {
          return null
          // return d3.timeFormat("%b")(date)
        }
        else {
          return d3.timeFormat("%Y")(date)
        }
      })




    // .selectAll('text')
    // .attr('transform', 'rotate(-45) translate(-16, -13)')

    const e = svg
      // .attr('transform', 'rotate(90) translate(500,550)')
      .selectAll("g.cvTitle")
      .data(events)
      .join("g")
      .attr("id",
        d => slugify(d.cvTitle)
      )
      .style("opacity", enterOpacity)
      .on("mouseenter", function () {
        // d3.select(this).raise().select("text").style("opacity", 1)
        d3.selectAll('#' + this.id)
          .style("color", themer.colors.primary)
          .style("opacity", 1)
      })
      .on("mouseleave", function () {
        // d3.select(this).lower().select("text").style("opacity", 0)
        d3.selectAll('#' + this.id)
          .style("color", themer.colors.text)
          .style("opacity", enterOpacity)
      })

    e.append("rect")
      .attr("height", yScale.bandwidth())
      .attr("stroke", themer.colors.background)
      .attr("stroke-width", 2)
      .attr("x", d => xScale(d.d3StartDate))
      .attr("y", d => yScale(nRows - 1 - d.yIndex))
      .attr("fill", themer.colors.secondary)
      .transition()
      .delay(function (d, i) { return i * anidelay; })
      .ease(aniease)
      .duration(anidur)
      .attr("fill", themer.colors.primary)
      .attr("width", d =>
        d.d3StartDate >= d.d3EndDate
          ? 3
          //reversed
          //: xScale(d.d3StartDate) - xScale(d.d3EndDate)
          : xScale(d.d3StartDate) - xScale(d.d3EndDate)
      )
      .attr("x", 0)
      .attr("y", 0)
      .attr(
        "transform",
        //reversed
        //d => `translate(${xScale(d.d3StartDate)}, ${yScale(d.yIndex) + 2})`
        d => `translate(${xScale(d.d3EndDate)}, ${yScale(nRows - 1 - d.yIndex)})`
      )
    // .attr("stroke-opacity", .5)
    //   .style("opacity", 0)
    //   .style("pointer-events", "none")
    svg.insert("text")
      .text("Now")
      .attr("y", H + 18)

    svg
      .append("g")
      .attr("class", "axis")
      //reversed
      //.attr("transform", `translate(0, 18)`)
      .attr("transform", `translate(0, ${H + 7})`)
      .call(xAxis)

    // svg.append("line")
    //   .attr("class","prsnt")
    //   .attr("x1", 0)
    //   .attr("y1", H+2)
    //   .attr("x2", 0)
    //   .attr("y2", H+2)
    //   .attr("transform", `translate(5.5, 0)`)
    //   .attr("stroke-width", "2")
    //   .attr("stroke-dasharray", ("2, 2"))
    //   .attr("stroke", themer.colors.primary)
    //   .transition()
    //   .delay(events.length*anidelay)
    //   .ease(aniease)
    //   .duration(anidur)
    //   .attr("y1", 0)
    //   .attr("y2", H+2)

    svg.selectAll(".tick line,.domain")
      .attr("stroke", themer.colors.gray)
      .attr("stroke-width", 1)


    svg.selectAll("text")
      .style("font-size", themer.fontSizes[1])
      .style("font-weight", themer.fontWeights.body)
      .style("font-family", themer.fonts.body)
      .attr("color", themer.colors.gray)
      // .style("letter-spacing", "-.5px")
      .attr("opacity", 0)
      .attr("transform", `translate(0, 15)`)
      .transition()
      .delay(function (d, i) { return anidelay * 10 - i * 100; })
      .ease(aniease)
      .duration(anidur * 5)
      .style("opacity", 1)
      .attr("transform", `translate(0, 5)`)

    svg.selectAll(".tick line")
      .attr("stroke", themer.colors.gray)
      .attr("stroke-width", .5)
      .transition()
      .delay(function (d, i) { return anidelay * 10 - i * 100; })
      .ease(aniease)
      .duration(anidur)
      .attr("y2", -H - 14)
      .attr("stroke-dasharray", ("2, 2"))


    return svg.node()
  }
  render() {
    return (
      <svg
        sx=
        {{
          display: ['none', 'none', 'none', 'block'],
          mb: '3',mt:'5'
        }}
        viewBox="0 0 1200 80" ref="canvas" />
    )
  }
}

export default AboutCVD3
